import { unstable_createMuiStrictModeTheme } from '@material-ui/core'

import colors from 'common/styles/colors'
import { spacingUnit } from 'common/styles/spacing'
import typography, { headingTypography } from 'common/styles/typography'

export default unstable_createMuiStrictModeTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 880,
      lg: 1160,
      xl: 1441,
    },
  },
  palette: {
    primary: {
      main: colors.primary500,
      light: colors.primary500,
      contrastText: colors.white,
    },
    error: { main: colors.error500 },
    secondary: { main: colors.blackOff, contrastText: colors.white },
    text: { primary: colors.blackOff },
    background: { default: colors.white },
  },
  typography: {
    ...typography,
    h1: headingTypography,
    h2: headingTypography,
    h3: headingTypography,
    h4: headingTypography,
    h5: headingTypography,
    body1: typography,
    body2: typography,
  },
  overrides: {
    MuiButton: {
      root: {
        minHeight: 40,
        borderRadius: 20,
        textTransform: 'none',
      },
      sizeLarge: {
        minHeight: 48,
        borderRadius: 24,
      },
      label: {
        fontWeight: 600,
      },
      outlined: {
        borderColor: colors.blackOff,
      },
    },
  },
  spacing: spacingUnit,
})
