import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useSnackbar } from 'notistack'

import { useMediaQuery, Button } from '@material-ui/core'

import { Lottie } from '@crello/react-lottie'

import { useTranslate } from 'i18n'

import { useApiClient } from 'common/api'

import { useSessionActions } from 'common/session'

import Logo from 'components/Logo'
import ScrollToTop from 'components/ScrollToTop'
import Page from 'components/Page'
import HeaderContainer from 'components/HeaderContainer'
import Fixed from 'components/Fixed'
import Container from 'components/Container'
import ScrollIndicator from 'components/ScrollIndicator'
import SubmitButton from 'components/SubmitButton'

import feedbackAnimation from './images/feedback.json'

const Home: React.FC = () => {
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const t = useTranslate()

  const client = useApiClient()

  const { logout } = useSessionActions()

  const [submitting, setSubmitting] = useState(false)

  const mobile = useMediaQuery<any>((theme) => theme.breakpoints.down('xs'))
  const compact = useMediaQuery<any>((theme) => theme.breakpoints.down('md'))

  const createRoom = useCallback(async () => {
    setSubmitting(true)

    try {
      const { id } = await client.post('/videochat')

      history.push(`/room/${id}`)
    } catch (e) {
      console.error(e)
      enqueueSnackbar(t('room.create.error'), { variant: 'error' })
      setSubmitting(false)
    }
  }, [t, client, history, enqueueSnackbar])

  const openProfile = useCallback(() => history.push('/profile'), [history])

  return (
    <>
      <Page p={compact ? 2 : 3} justifyContent="space-between" maxWidth={600}>
        <HeaderContainer mb={compact ? 3 : 6}>
          <Logo large={!compact} />
        </HeaderContainer>

        <Container style={{ alignItems: 'center' }}>
          <Lottie
            config={{
              loop: true,
              autoplay: true,
              animationData: feedbackAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width={mobile ? '280px' : '400px'}
            height={mobile ? '280px' : '400px'}
          />
        </Container>

        <Container>
          <Container mb={2}>
            <SubmitButton
              label={t('room.create')}
              fullWidth
              disabled={submitting}
              onClick={createRoom}
            />
          </Container>

          <Container mb>
            <Button
              size="large"
              color="primary"
              fullWidth
              onClick={openProfile}
            >
              {t('profile')}
            </Button>
          </Container>

          <Container>
            <Button size="large" color="primary" fullWidth onClick={logout}>
              {t('logout')}
            </Button>
          </Container>
        </Container>
      </Page>

      <Fixed horizontal="right" vertical="bottom" spacing>
        <ScrollIndicator shade={compact} />
      </Fixed>

      <ScrollToTop />
    </>
  )
}

export default Home
