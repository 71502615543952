import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default (value: string): string => {
  const result = parsePhoneNumberFromString(value, 'SE')
  if (!result) {
    throw new Error('could not parse phone number')
  }
  if (!result.isValid) {
    throw new Error('not a valid phone number')
  }
  return String(result.number)
}
