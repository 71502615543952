import React from 'react'
import classNames from 'classnames'

import { Button } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CheckIcon from '@material-ui/icons/Check'
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckedIcon from '@material-ui/icons/CheckBoxOutlined'

import colors from 'common/styles/colors'

import Text from './Text'
import Markdown from './Markdown'

const useStyles = makeStyles(({ spacing }) => ({
  option: {
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
    textAlign: 'left',
  },

  optionContent: {
    flex: 1,
  },

  optionDescription: {
    color: 'inherit',
  },

  optionIcon: {
    marginLeft: spacing(3),
  },
}))

const Option: React.FC<{
  label: any
  description?: any
  disabled?: boolean
  answered?: boolean
  multiple?: boolean
  checked?: boolean
  onClick?: () => void
  className?: string
}> = ({
  label,
  description,
  disabled,
  answered,
  multiple,
  checked,
  onClick,
  className,
}) => {
  const classes = useStyles()

  return (
    <Button
      variant={checked && (multiple || answered) ? 'contained' : 'outlined'}
      color="primary"
      size="large"
      disabled={disabled}
      className={classNames(classes.option, className)}
      onClick={onClick}
    >
      <div
        className={classes.optionContent}
        style={{
          color:
            checked && (multiple || answered) ? colors.white : colors.blackOff,
        }}
      >
        {typeof label === 'string' ? (
          <Text color="inherit" bold>
            {label}
          </Text>
        ) : (
          label
        )}

        {description && (
          <Markdown
            typographyVariant="body2"
            className={classes.optionDescription}
          >
            {description}
          </Markdown>
        )}
      </div>

      {multiple ? (
        checked ? (
          <CheckedIcon className={classes.optionIcon} />
        ) : (
          <UncheckedIcon className={classes.optionIcon} />
        )
      ) : checked || answered ? (
        <CheckIcon
          className={classes.optionIcon}
          style={{ visibility: checked ? 'visible' : 'hidden' }}
        />
      ) : (
        <ArrowForwardIcon className={classes.optionIcon} />
      )}
    </Button>
  )
}

export default Option
