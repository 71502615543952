import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import { makeStyles } from '@material-ui/core/styles'

import colors from 'common/styles/colors'

import Page from './Page'
import Text from './Text'

const useStyles = makeStyles(() => ({
  icon: {
    color: colors.primary500,
  },
}))

const Loading: React.FC<{ message?: string }> = ({ message }) => {
  const classes = useStyles()

  return (
    <Page alignItems="center" justifyContent="center" p={2}>
      <CircularProgress size={100} className={classes.icon} />

      {!!message && (
        <Text mt={6} textAlign="center">
          {message}
        </Text>
      )}
    </Page>
  )
}

export default Loading
