import React, { useEffect, useState, useCallback } from 'react'

import firebase from 'firebase/app'

import { useFirebase } from '../firebase'

import { useApiClient } from '../api'

import LocaleProvider from 'i18n/LocaleProvider'
import { defaultLocale } from 'i18n/types'

import { mergeUserData, Profile } from '../users'

import * as actions from './actions'
import context from './context'

const Provider: React.FC = ({ children }) => {
  const { auth, firestore } = useFirebase()

  const client = useApiClient()

  const [claims, setClaims] = useState<
    { [key: string]: any } | false | undefined
  >()
  const [profile, setProfile] = useState<Profile | undefined>()

  const saveProfile = useCallback(actions.saveProfile(client), [client])
  const logout = useCallback(actions.logout(auth), [auth])

  const setClaimsFromToken = useCallback(async (authUser: firebase.User) => {
    setClaims((await authUser.getIdTokenResult(true)).claims)
  }, [])

  useEffect(() => {
    const subscriber = auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setClaimsFromToken(authUser)
      } else {
        setClaims(false)
      }
    })
    return subscriber
  }, [])

  const authId = claims ? claims.user_id : undefined

  useEffect(() => {
    if (authId) {
      return firestore()
        .doc(`claims/${authId}`)
        .onSnapshot(async () => {
          const authUser = auth().currentUser
          if (authUser) {
            setClaimsFromToken(authUser)
          }
        })
    }
  }, [firestore, authId])

  const userId = claims ? claims.id : undefined

  useEffect(() => {
    if (userId) {
      return firestore()
        .doc(`profiles/${userId}`)
        .onSnapshot(async (snapshot) => {
          const authUser = auth().currentUser
          if (authUser) {
            setProfile(snapshot.data() || {})
          }
        })
    } else {
      setProfile(undefined)
    }
  }, [firestore, userId])

  const user =
    userId && claims && profile
      ? mergeUserData(userId, claims, profile)
      : undefined

  return (
    <context.Provider
      value={{
        actions: { saveProfile, logout },
        loaded: claims === false || !!user,
        user,
      }}
    >
      <LocaleProvider locale={user?.locale || defaultLocale}>
        {children}
      </LocaleProvider>
    </context.Provider>
  )
}

export default Provider
