import isLocalhost from '../utils/isLocalhost'

import getConfig from './getConfig'

const localhost = isLocalhost()

const defaultWebConfig = getConfig(
  localhost
    ? {
        localApi: true,
        localHosting: true,
        localStore: false,
        persistentCache: false,
      }
    : { persistentCache: false }
)

export default defaultWebConfig
