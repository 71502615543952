import React from 'react'

import classNames from 'classnames'

import { makeStyles } from '@material-ui/core'

import { getSpacingStyle, SpacingProps } from 'common/styles/spacing'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}))

export type ContainerProps = SpacingProps & {
  component?: 'div' | 'ol' | 'ul' | 'p'
  fullWidth?: boolean
  flexDirection?: 'column' | 'row'
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
  className?: string
  style?: any
  children?: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({
  component = 'div',
  children,
  fullWidth = true,
  flexDirection = 'column',
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  className,
  style,
  ...otherProps
}) => {
  const classes = useStyles()

  return React.createElement(
    component,
    {
      className: classNames(classes.root, className),
      style: {
        flexDirection,
        alignItems,
        justifyContent,
        ...(fullWidth ? { width: '100%' } : {}),
        ...getSpacingStyle(otherProps),
        ...style,
      },
    },
    children
  )
}

export default Container
