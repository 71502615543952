import React, { useCallback } from 'react'

import classNames from 'classnames'

import { TextField } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import Text from './Text'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },

  input: {},

  short: {
    maxWidth: 300,
  },
}))

export type TextInputProps = {
  id?: string
  type?: 'text' | 'number' | 'date' | 'tel' | 'email'
  label?: string
  value?: string
  unit?: string
  multiline?: boolean
  maxLength?: number
  placeholder?: string
  disabled?: boolean
  autoFocus?: boolean
  endAdornment?: any
  startAdornment?: any
  errorMessage?: string
  onFocus?: () => void
  onBlur?: () => void
  onChange?: (value: string) => void
  onSubmit?: () => void
  className?: string
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  type,
  label,
  value,
  unit,
  multiline,
  maxLength,
  placeholder,
  disabled,
  autoFocus,
  endAdornment,
  startAdornment,
  errorMessage,
  onFocus,
  onBlur,
  onChange,
  onSubmit,
  className,
}) => {
  const classes = useStyles()

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      onChange && onChange(event.target.value),
    [onChange]
  )
  const onKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.which === 13 && !event.shiftKey) {
        onSubmit && onSubmit()
      }
    },
    [onSubmit]
  )

  return (
    <TextField
      variant="outlined"
      label={label}
      placeholder={placeholder}
      multiline={multiline}
      value={value || ''}
      autoFocus={autoFocus}
      disabled={disabled}
      InputProps={{
        startAdornment,
        endAdornment: unit ? (
          <Text bold ml={2}>
            {unit}
          </Text>
        ) : (
          endAdornment
        ),
        className: classes.input,
      }}
      inputProps={{
        id,
        type: type === 'number' ? 'text' : type,
        maxLength,
      }}
      error={!!errorMessage}
      helperText={errorMessage}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onTextChange}
      onKeyPress={onKeyPress}
      className={classNames(
        classes.root,
        (type === 'number' || type === 'date') && classes.short,
        className
      )}
    />
  )
}

export default TextInput
