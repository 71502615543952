import React, { useState, useEffect } from 'react'

import classNames from 'classnames'

import ArrowIcon from '@material-ui/icons/ArrowDownward'

import IconButton from '@material-ui/core/IconButton'

import { makeStyles } from '@material-ui/core/styles'

import colors from 'common/styles/colors'

const defaultOffset = 24

function getWindowHeight() {
  return window.innerHeight
}

function getDocumentHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  )
}

function getScrollTop() {
  return (document.scrollingElement || document.documentElement).scrollTop
}

function isScrolledToBottom(offset: number) {
  const windowHeight = getWindowHeight()
  const documentHeight = getDocumentHeight()
  const scrollTop = getScrollTop()

  return windowHeight + scrollTop > documentHeight - offset
}

const scrollDown = () =>
  window.scrollTo(0, getScrollTop() + getWindowHeight() / 2)

const useStyles = makeStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    [down('xs')]: {
      padding: spacing(),
    },
  },

  shadow: {
    backgroundColor: colors.primary100,
    '&:hover': {
      backgroundColor: colors.primary150,
    },
    boxShadow:
      '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
  },

  icon: {
    width: 36,
    height: 36,
    color: colors.blackOff,
  },
}))

const ScrollIndicator: React.FC<{
  shade?: boolean
  offset?: number
  className?: string
}> = ({ shade, offset = defaultOffset, className }) => {
  const classes = useStyles()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const fn = () => setVisible(!isScrolledToBottom(offset))
    fn()
    window.addEventListener('scroll', fn)
    window.addEventListener('resize', fn)
    const timer = setInterval(fn, 100)
    return () => {
      window.removeEventListener('scroll', fn)
      window.removeEventListener('resize', fn)
      clearInterval(timer)
    }
  }, [])

  return visible ? (
    <IconButton
      onClick={scrollDown}
      className={classNames(classes.root, shade && classes.shadow, className)}
    >
      <ArrowIcon className={classes.icon} />
    </IconButton>
  ) : null
}

export default ScrollIndicator
