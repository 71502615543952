import React from 'react'
import classNames from 'classnames'

import IconButton from '@material-ui/core/IconButton'

import { makeStyles } from '@material-ui/core/styles'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import colors from 'common/styles/colors'

const useStyles = makeStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    [down('xs')]: {
      padding: spacing(),
    },
  },

  shadow: {
    backgroundColor: colors.primary100,
    '&:hover': {
      backgroundColor: colors.primary150,
    },
    boxShadow:
      '0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
  },

  icon: {
    height: 36,
    width: 36,
    color: colors.blackOff,
  },
}))

const Back: React.FC<{
  shade?: boolean
  onClick?: () => void
  className?: string
}> = ({ shade, onClick, className }) => {
  const classes = useStyles()

  return (
    <IconButton
      className={classNames(classes.root, shade && classes.shadow, className)}
      onClick={() => onClick && onClick()}
    >
      <ArrowBackIcon className={classes.icon} />
    </IconButton>
  )
}

export default Back
