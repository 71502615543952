import React, { useEffect, useState } from 'react'

import { initializeApp } from 'firebase/app'

import { useConfig } from '../config'

import context from './context'
import { Modules } from './types'

const Provider: React.FC<Modules> = ({
  auth,
  firestore,
  storage,
  children,
}) => {
  const config = useConfig()

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized) {
      initializeApp(config.firebase)

      const db = firestore()

      if (config.emulatedStore) {
        db.settings({
          host: `localhost:${config.local.firestorePort}`,
          ssl: false,
        })
      }

      if (config.persistentCache) {
        db.enablePersistence && db.enablePersistence()
      } else {
        db.clearPersistence && db.clearPersistence()
      }

      setInitialized(true)
    }
  }, [config, initialized])

  return initialized ? (
    <context.Provider value={{ auth, firestore, storage }}>
      {children}
    </context.Provider>
  ) : null
}

export default Provider
