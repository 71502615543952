import React, { useState, useCallback } from 'react'

import isValidPhoneNumber from 'common/utils/isValidPhoneNumber'

import TextInput, { TextInputProps } from './TextInput'
import { useTranslate } from 'i18n'

const PhoneNumberInput: React.FC<
  TextInputProps & {
    onPhoneNumberChange?: (value: string, valid: boolean) => void
  }
> = (props) => {
  const {
    value,
    errorMessage,
    onBlur,
    onChange,
    onPhoneNumberChange,
    onSubmit,
  } = props

  const t = useTranslate()

  const [dirty, setDirty] = useState(false)

  const phoneNumber = value || ''
  const empty = !phoneNumber.length
  const valid = isValidPhoneNumber(phoneNumber)

  const _onBlur = useCallback(() => {
    setDirty(!empty && !valid)
    onBlur && onBlur()
  }, [onBlur, empty, valid])

  const _onChange = useCallback(
    (newValue: string) => {
      onChange && onChange(newValue)
      onPhoneNumberChange &&
        onPhoneNumberChange(newValue, isValidPhoneNumber(newValue))
    },
    [onChange, onPhoneNumberChange]
  )

  return (
    <TextInput
      {...props}
      type="tel"
      errorMessage={
        errorMessage
          ? errorMessage
          : !valid && phoneNumber.length && dirty
          ? t('phoneNumber.invalid')
          : undefined
      }
      onBlur={_onBlur}
      onChange={_onChange}
      onSubmit={valid ? onSubmit : undefined}
    />
  )
}

export default PhoneNumberInput
