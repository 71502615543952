export default {
  'app.name': 'Min Doktor',
  'app.tagline': 'A quick and simple way to meet your patients.',

  yes: 'Yes',
  no: 'No',
  unknown: "Don't know",
  confirm: 'Confirm',
  next: 'Next',
  skip: 'Skip',
  login: 'Login',
  logout: 'Logout',
  anonymous: 'Anonymous',
  notAllowed: 'Not allowed',
  name: 'Name',
  title: 'Title',

  email: 'Email',
  'email.placeholder': 'youremail@email.com',
  'email.invalid': 'Invalid email',

  phoneNumber: 'Phone number',
  'phoneNumber.placeholder': '+46765260000',
  'phoneNumber.invalid': 'Invalid phone number',

  ssn: 'Personal number',
  'ssn.placeholder': 'YYYYMMDD-XXXX',
  'ssn.invalid': 'Invalid personal number',

  'question.placeholder': 'Write your answer here',

  profile: 'My profile',
  'profile.save': 'Save profile',
  'profile.save.error': 'Failed to save profile',

  'locale.label': 'Language',
  'locale.english': 'English',
  'locale.swedish': 'Swedish',

  'room.create': 'Create a chat room',
  'room.create.error': 'Failed to create a chat room',
  'room.description':
    'You can send an invite to whomever you want. The link is valid for 24 hours. After that you are of course free to create a new chat room.',
  'room.clipboard.copy': 'Copy to clipboard',
  'room.clipboard.copied': 'Copied to clipboard',
  'room.invite.sent': 'Sent invite',
  'room.invite.error': 'Failed to send invite',
  'room.invite.email': 'Send invite by email',
  'room.invite.sms': 'Send invite by SMS',
  'room.videochat.open': 'Open videochat',

  'login.bankid': 'Login with BankIS',
  'login.bankid.error': 'Failed to login with BankID',
  'login.bankid.description':
    'We use BankID for a safe and secure identification.',
  'login.bankid.this.login': 'BankID on this device',
  'login.bankid.this.polling':
    'The BankID app will start automatically if it is installed on this device.',
  'login.bankid.other.login': 'BankID on other device',
  'login.bankid.other.polling':
    'Start the BankID app on the device where it is installed.',

  'login.email': 'Login with email',
  'login.email.error': 'Failed to login with email',
  'login.email.link.description':
    "We're going to send a link to your email. Click the link to login.",
  'login.email.link.error': 'Failed to send link',
  'login.email.link.send': 'Send link',
  'login.email.sent.description':
    "We've sent a link to your email. Click the link to login.",
  'login.email.confirm': 'Confirm email',
  'login.email.confirm.description': 'You need to confirm your email to login.',

  'login.google': 'Login with Google',

  'invite.email.subject': "I'd like to have a chat with you!",
  'invite.email.body': 'Click <a href="{link}">here</a> to join the videochat.',
  'invite.sms.text': "I'd like to have a chat with you!",

  integrity: 'Integrity',
  'integrity.policy.label': 'Your privacy, our priority',
  'integrity.policy.description':
    'Our privacy policy and terms of service comply with the General Data Protection Regulation ([GDPR](https://en.wikipedia.org/wiki/General_Data_Protection_Regulation))and gives you, the user, the opportunity to control what personal data is shared, and not shared, with Min Doktor. Please read our new [privacy policy](modal:integrityPolicy) and [Terms of Service](modal:tos) and choose ‘OK’ to continue.',
  'integrity.policy.accept': 'OK',
  'integrity.policy.settings': 'Personal settings',
  'integrity.settings.label': 'Personal settings',
  'integrity.settings.description':
    'You have the option to change specific personal settings below. You can always make changes to these settings at a later date.\n\n_These settings only affect non-medical matters. Your chosen settings in no way affect your ability to receive healthcare from us. Your patient notes are always kept confidential, and as healthcare providers we are legally obliged to comply with the Swedish Patient Safety Act (PSL)._',
  'integrity.settings.marketing.label': 'Marketing communications',
  'integrity.settings.marketing.description':
    "Min Doktor will contact you when we launch new features, or to send newsletters and advice that we think you'll find interesting.",
  'integrity.settings.tracking.label': 'Personal options',
  'integrity.settings.tracking.description':
    "Let us analyse your user behaviour in order to create a more customised user experience for you. We'll look at, for example, how you found us and what browser you use.",

  'register.name': "What's your name?",
  'register.error': 'Failed to save profile',

  'videochat.join': 'Join',
}
