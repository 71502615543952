export default {
  black: '#000000',
  blackOff: '#00151E',

  white: '#FFFFFF',

  primary100: '#f9eded',
  primary150: '#f5dedd',
  primary500: '#E52427',
  primaryv500: '#FF383B',

  error500: '#897306',
}
