import React from 'react'

import Container, { ContainerProps } from './Container'

const HeaderContainer: React.FC<ContainerProps> = ({
  alignItems = 'center',
  justifyContent = 'center',
  ...otherProps
}) => {
  return (
    <Container
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...otherProps}
    />
  )
}

export default HeaderContainer
