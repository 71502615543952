import { useContext } from 'react'

import context from './context'

import { ApiClient } from './types'

export const useApiClient = (): ApiClient => {
  const client = useContext(context)

  if (!client) {
    throw new Error('no api client')
  }

  return client
}
