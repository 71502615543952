import React, { useState, useCallback } from 'react'

import isValidEmail from 'common/utils/isValidEmail'

import TextInput, { TextInputProps } from './TextInput'
import { useTranslate } from 'i18n'

const EmailInput: React.FC<
  TextInputProps & {
    onEmailChange?: (value: string, valid: boolean) => void
  }
> = (props) => {
  const {
    value,
    errorMessage,
    onBlur,
    onChange,
    onEmailChange,
    onSubmit,
  } = props

  const t = useTranslate()

  const [dirty, setDirty] = useState(false)

  const email = value || ''
  const empty = !email.length
  const valid = isValidEmail(email)

  const _onBlur = useCallback(() => {
    setDirty(!empty && !valid)
    onBlur && onBlur()
  }, [onBlur, empty, valid])

  const _onChange = useCallback(
    (newValue: string) => {
      onChange && onChange(newValue)
      onEmailChange && onEmailChange(newValue, isValidEmail(newValue))
    },
    [onChange, onEmailChange]
  )

  return (
    <TextInput
      {...props}
      type="email"
      errorMessage={
        errorMessage
          ? errorMessage
          : !valid && email.length && dirty
          ? t('email.invalid')
          : undefined
      }
      onBlur={_onBlur}
      onChange={_onChange}
      onSubmit={valid ? onSubmit : undefined}
    />
  )
}

export default EmailInput
