import { useContext } from 'react'

import { User } from '../users'

import context from './context'
import { Actions } from './types'

export const useCurrentUser = (): User => {
  const session = useContext(context)

  if (!session) {
    throw new Error('No session')
  }

  if (!session.user) {
    throw new Error('No user')
  }

  return session.user
}

export const useSessionActions = (): Actions => {
  const session = useContext(context)

  if (!session) {
    throw new Error('No session')
  }

  return session.actions
}

export const useLogout = (): (() => void) => {
  const session = useContext(context)

  if (!session) {
    throw new Error('No session')
  }

  return session.actions.logout
}
