import React, { useState } from 'react'

import { useConfig } from '../config'
import { useFirebase } from '../firebase'

import context from './context'
import createClient from './createClient'

const Provider: React.FC = ({ children }) => {
  const config = useConfig()

  const { auth } = useFirebase()

  const [client] = useState(
    createClient(`${config.functionsUrl}/api`, async () =>
      auth().currentUser?.getIdToken()
    )
  )

  return <context.Provider value={client}>{children}</context.Provider>
}

export default Provider
