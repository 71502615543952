import React, { useEffect, useContext } from 'react'

import context from './context'

const Gate: React.FC<{
  loadingIndicator?: React.ReactNode
  onRedirect?: () => void
}> = ({ loadingIndicator, onRedirect, children }) => {
  const session = useContext(context)

  const loaded = session?.loaded
  const user = session?.user

  useEffect(() => {
    if (loaded && (!user?.allowed || !user?.registered)) {
      onRedirect && onRedirect()
    }
  }, [loaded, user])

  return <>{session?.user?.allowed ? children : loadingIndicator}</>
}

export default Gate
