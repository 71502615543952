import { ApiClient } from './types'

export default (
  baseUrl: string,
  getToken: () => Promise<string | undefined>
): ApiClient => {
  const _fetch: ApiClient['fetch'] = async ({
    url,
    method = 'GET',
    body,
    headers,
  }) => {
    const allHeaders: Record<string, any> = {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    }

    if (!allHeaders.Authorization) {
      const token = await getToken()
      if (token) {
        allHeaders.Authorization = `Bearer ${token}`
      }
    }

    const response = await fetch(`${baseUrl}${url}`, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: allHeaders,
    })

    if (response.status >= 400) {
      throw new Error(
        `request error ${response.status} (${response.statusText})`
      )
    }

    const text = (await response.text()).trim()

    if (text && text !== 'OK') {
      return JSON.parse(text)
    }

    return undefined
  }

  const _get: ApiClient['get'] = (url) => _fetch({ url, method: 'GET' })
  const _post: ApiClient['post'] = (url, body) =>
    _fetch({ url, method: 'POST', body })
  const _put: ApiClient['put'] = (url, body) =>
    _fetch({ url, method: 'PUT', body })
  const _delete: ApiClient['delete'] = (url) =>
    _fetch({ url, method: 'DELETE' })

  return {
    fetch: _fetch,
    get: _get,
    post: _post,
    put: _put,
    delete: _delete,
  }
}
