import React from 'react'

import { Typography } from '@material-ui/core'

import { getSpacingStyle, SpacingProps } from 'common/styles/spacing'

export type TextProps = SpacingProps & {
  component?: 'div' | 'li'
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2'
  bold?: boolean
  italic?: boolean
  color?: string
  textAlign?: 'left' | 'center' | 'right'
  style?: any
  className?: string
}

const Text: React.FC<TextProps> = ({
  component,
  variant = 'body1',
  bold,
  italic,
  color,
  textAlign = 'left',
  children,
  style,
  className,
  ...otherProps
}) => {
  return (
    <Typography
      component={
        component ||
        (variant === 'h1' ||
        variant === 'h2' ||
        variant === 'h3' ||
        variant === 'h4' ||
        variant === 'h5' ||
        variant === 'h6'
          ? variant
          : 'div')
      }
      variant={variant}
      className={className}
      style={{
        textAlign,
        ...(color ? { color } : {}),
        ...(bold ? { fontWeight: 600 } : {}),
        ...(italic ? { fontStyle: 'italic' } : {}),
        ...getSpacingStyle(otherProps),
        ...style,
      }}
    >
      {children}
    </Typography>
  )
}

export default Text
