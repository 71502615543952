import React from 'react'

import { useMediaQuery } from '@material-ui/core'

import Text, { TextProps } from './Text'

const Label: React.FC<TextProps> = ({ children, ...otherProps }) => {
  const xs = useMediaQuery<any>((theme) => theme.breakpoints.down('xs'))

  return (
    <Text
      variant={
        typeof children === 'string' && children.length > 40
          ? xs
            ? 'h5'
            : 'h4'
          : xs
          ? 'h4'
          : 'h3'
      }
      {...otherProps}
    >
      {children}
    </Text>
  )
}

export default Label
