import React from 'react'

import classNames from 'classnames'

import { Button } from '@material-ui/core'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslate } from 'i18n'

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    minWidth: 160,
    justifyContent: 'space-between',
    textAlign: 'left',
  },

  buttonIcon: {
    marginLeft: spacing(3),
  },
}))

const SubmitButton: React.FC<{
  variant?: 'contained' | 'outlined'
  label?: string
  IconComponent?: any
  disabled?: boolean
  fullWidth?: boolean
  onClick: () => void
  className?: string
  style?: any
}> = ({
  variant = 'contained',
  label,
  IconComponent = ArrowForwardIcon,
  disabled,
  fullWidth,
  onClick,
  className,
  style,
}) => {
  const classes = useStyles()

  const t = useTranslate()

  return (
    <Button
      variant={variant}
      color="primary"
      size="large"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      className={classNames(classes.button, className)}
      style={style}
    >
      {label || t('next')}
      <IconComponent className={classes.buttonIcon} />
    </Button>
  )
}

export default SubmitButton
