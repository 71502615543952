import { useContext } from 'react'

import context from './context'

import { Modules } from './types'

export const useFirebase = (): Modules => {
  const modules = useContext(context)

  if (!modules) {
    throw new Error('no firebase modules')
  }

  return modules
}
