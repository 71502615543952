import { useContext, useCallback } from 'react'

import context from './context'
import translate, { Key } from './translate'
import { Locale } from './types'

export const useLocale = (): Locale => {
  return useContext(context).locale
}

export const useTranslate = (): ((key: Key) => string) => {
  const locale = useLocale()

  return useCallback(
    (key: Key) => {
      return translate(locale, key)
    },
    [locale]
  )
}
