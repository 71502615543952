import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useSnackbar } from 'notistack'

import { useMediaQuery, Button } from '@material-ui/core'

import { useTranslate, defaultLocale } from 'i18n'

import { useCurrentUser, useSessionActions } from 'common/session'

import Logo from 'components/Logo'
import ScrollToTop from 'components/ScrollToTop'
import Page from 'components/Page'
import HeaderContainer from 'components/HeaderContainer'
import Fixed from 'components/Fixed'
import Back from 'components/Back'
import useScrolling from 'common/utils/useScrolling'
import Label from 'components/Label'
import ButtonsContainer from 'components/ButtonsContainer'
import Option from 'components/Option'
import Container from 'components/Container'
import TextInput from 'components/TextInput'
import ScrollIndicator from 'components/ScrollIndicator'

const Profile: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()

  const compact = useMediaQuery<any>((theme) => theme.breakpoints.down('md'))

  const history = useHistory()

  const t = useTranslate()

  const currentUser = useCurrentUser()
  const { saveProfile } = useSessionActions()

  const initialState = {
    name: currentUser.name,
    title: currentUser.title,
    allowMarketing: currentUser.allowMarketing,
    allowTracking: currentUser.allowTracking,
    locale: currentUser.locale,
  }

  const [state, setState] = useState(initialState)
  const [submitting, setSubmitting] = useState(false)

  const scrolling = useScrolling()

  const valid = !!state.name?.trim()

  const save = useCallback(async () => {
    if (!valid) {
      return
    }

    setSubmitting(true)

    try {
      await saveProfile({ ...state })
    } catch (e) {
      console.error(e)
      enqueueSnackbar(t('profile.save.error'), { variant: 'error' })
    }

    setSubmitting(false)
  }, [t, enqueueSnackbar, saveProfile, state, valid])

  const goBack = useCallback(() => history.push('/'), [history])

  return (
    <>
      <Page p={compact ? 2 : 3} justifyContent="space-between" maxWidth={600}>
        <HeaderContainer mb={compact ? 3 : 6}>
          <Logo large={!compact} />
        </HeaderContainer>

        <Container>
          <Container mb={compact ? 3 : 6}>
            <Label mb={compact ? 2 : 3}>{t('profile')}</Label>

            <Container mb={2}>
              <TextInput
                label={t('name')}
                value={state.name}
                onChange={(name) => setState({ ...state, name })}
              />
            </Container>

            <Container mb={2}>
              <TextInput
                label={t('title')}
                value={state.title}
                onChange={(title) => setState({ ...state, title })}
              />
            </Container>

            {currentUser.email && (
              <Container mb={2}>
                <TextInput
                  label={t('email')}
                  value={currentUser.email}
                  disabled
                />
              </Container>
            )}

            {currentUser.ssn && (
              <Container mb={2}>
                <TextInput label={t('ssn')} value={currentUser.ssn} disabled />
              </Container>
            )}
          </Container>

          <Container mb={compact ? 3 : 6}>
            <Label mb={compact ? 2 : 3}>{t('locale.label')}</Label>

            <Option
              label={t('locale.english')}
              checked={(state.locale || defaultLocale) === 'en-GB'}
              answered
              onClick={() => setState({ ...state, locale: 'en-GB' })}
            />

            <Option
              label={t('locale.swedish')}
              checked={(state.locale || defaultLocale) === 'sv-SE'}
              answered
              onClick={() => setState({ ...state, locale: 'sv-SE' })}
            />
          </Container>

          <Container>
            <Label mb={compact ? 2 : 3}>{t('integrity')}</Label>

            <Option
              label={t('integrity.settings.marketing.label')}
              description={t('integrity.settings.marketing.description')}
              multiple
              answered
              checked={state.allowMarketing}
              onClick={() =>
                setState({ ...state, allowMarketing: !state.allowMarketing })
              }
            />

            <Option
              label={t('integrity.settings.tracking.label')}
              description={t('integrity.settings.tracking.description')}
              multiple
              answered
              checked={state.allowTracking}
              onClick={() =>
                setState({ ...state, allowTracking: !state.allowTracking })
              }
            />
          </Container>
        </Container>

        <ButtonsContainer mt={compact ? 3 : 6}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              JSON.stringify(initialState) === JSON.stringify(state) ||
              !valid ||
              submitting
            }
            onClick={save}
          >
            {t('profile.save')}
          </Button>
        </ButtonsContainer>
      </Page>

      <Fixed horizontal="left" vertical="top" spacing>
        <Back shade={compact && scrolling} onClick={goBack} />
      </Fixed>

      <Fixed horizontal="right" vertical="bottom" spacing>
        <ScrollIndicator shade={compact} />
      </Fixed>

      <ScrollToTop />
    </>
  )
}

export default Profile
