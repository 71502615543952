const typography = {
  fontFamily: '"Graphik", -apple-system, BlinkMacSystemFont, sans-serif',
  lineHeight: 1.4,
}

export const headingTypography = {
  ...typography,
  lineHeight: 1.2,
  fontWeight: 700,
}

export default typography
