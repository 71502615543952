import React from 'react'

import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import colors from 'common/styles/colors'

import Container, { ContainerProps } from './Container'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    margin: '0 auto',
  },
}))

const Page: React.FC<
  ContainerProps & {
    backgroundColor?: string
    maxWidth?: number | string
    justifyContent?: 'flex-start' | 'flex-end' | 'space-between' | 'center'
    alignItems?: 'flex-start' | 'flex-end' | 'space-between' | 'center'
  }
> = ({
  className,
  style,
  backgroundColor = colors.primary100,
  maxWidth = 948,
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <>
      <Container
        className={classNames(classes.root, className)}
        style={{ ...style, justifyContent, alignItems, maxWidth }}
        {...otherProps}
      />

      <style>{`body { background-color: ${backgroundColor}; }`}</style>
    </>
  )
}

export default Page
