import React from 'react'

import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    height: 48,
  },

  large: {
    height: 64,
  },
}))

const Logo: React.FC<{ large?: boolean; className?: string }> = ({
  large,
  className,
}) => {
  const classes = useStyles()

  return (
    <svg
      viewBox="0 0 85 67.5"
      className={classNames(classes.root, large && classes.large, className)}
    >
      <path
        d="M62.4 0c-8-.2-15.6 3.9-19.9 10.7A22.98 22.98 0 0 0 22.6 0C8.3.3-2 13.6.3 27.7c4.1 24.8 40.2 39 42.2 39.8 2-.8 38.1-14.9 42.2-39.8C87 13.6 76.8.3 62.4 0zM42.5 52.3c-9.7 0-18.6-5.5-22.9-14.2h6.1a20.37 20.37 0 0 0 33.6 0h6.1a25.74 25.74 0 0 1-22.9 14.2z"
        fill="#e52427"
      />
      <path
        fill="#fff"
        d="M42.5 47c-6.7 0-13-3.3-16.8-8.9h-6.1A25.51 25.51 0 0 0 54 49.5C59 47 63 43 65.5 38h-6.1c-3.9 5.6-10.2 8.9-16.9 9z"
      />
      <path
        fill="#fff"
        d="M42.5 46.9c-7 0-13.1-3.5-16.8-8.9h-6.1c4.2 8.4 12.9 14.2 22.9 14.2 10 0 18.7-5.8 22.9-14.2h-6.1c-3.7 5.4-9.8 8.9-16.8 8.9z"
      />
    </svg>
  )
}

export default Logo
