import React from 'react'
import classNames from 'classnames'

import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'

const styles = ({ spacing, breakpoints: { down } }: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    spacing: {
      margin: spacing(2),
      [down('xs')]: {
        margin: spacing(),
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  horizontal?: 'left' | 'center' | 'right'
  vertical?: 'top' | 'center' | 'bottom'
  spacing?: boolean
  children?: React.ReactNode
  className?: string
}

const Fixed = withStyles(styles)(
  ({
    horizontal = 'left',
    vertical = 'top',
    spacing = false,
    children,
    classes,
    className,
  }: Props) => (
    <div
      className={classNames(
        classes.root,
        spacing && classes.spacing,
        className
      )}
      style={{
        top: vertical === 'top' || vertical === 'center' ? 0 : undefined,
        bottom: vertical === 'bottom' || vertical === 'center' ? 0 : undefined,
        left: horizontal === 'left' || horizontal === 'center' ? 0 : undefined,
        right:
          horizontal === 'right' || horizontal === 'center' ? 0 : undefined,
      }}
    >
      {children}
    </div>
  )
)

export default Fixed
