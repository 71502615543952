import { firestore } from 'firebase/app'

import { User } from './types'

export default (
  id: string,
  claims: { [key: string]: any },
  profile?: firestore.DocumentData
): User => {
  return {
    id,
    authId: claims.authId,
    email: claims.email,
    ssn: claims.ssn,
    name: profile?.name || claims.name,
    title: profile?.title,
    photoUrl: claims.photoUrl,
    locale: profile?.locale,
    allowMarketing: profile?.allowMarketing,
    allowTracking: profile?.allowTracking,
    allowed: claims.allowed,
    registered: claims.registered,
  }
}
