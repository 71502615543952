import React from 'react'

import Container, { ContainerProps } from './Container'

const ButtonsContainer: React.FC<ContainerProps> = ({
  flexDirection = 'row',
  alignItems = 'flex-end',
  justifyContent = 'flex-end',
  ...otherProps
}) => {
  return (
    <Container
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...otherProps}
    />
  )
}

export default ButtonsContainer
