import React from 'react'

import context from './context'
import { Locale } from './types'

const LocaleProvider: React.FC<{ locale: Locale }> = ({ locale, children }) => {
  return <context.Provider value={{ locale }}>{children}</context.Provider>
}

export default LocaleProvider
