import { useContext } from 'react'

import context from './context'
import { Config } from './types'

export const useConfig = (): Config => {
  const config = useContext(context)

  if (!config) {
    throw new Error('no config')
  }

  return config
}
