import React from 'react'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { auth, firestore, storage } from 'firebase/app'

import { CssBaseline, MuiThemeProvider } from '@material-ui/core'

import { SnackbarProvider } from 'notistack'

import ApiProvider from 'common/api/Provider'
import ConfigProvider from 'common/config/Provider'
import FirebaseProvider from 'common/firebase/Provider'
import SessionProvider from 'common/session/Provider'
import SessionGate from 'common/session/Gate'

import { defaultWebConfig } from 'common/config'

import redirectToLogin from 'common/session/redirectToLogin'

import 'common/styles/index.css'

import theme from 'components/theme'
import Loading from 'components/Loading'

import App from './App'

const Root: React.FC = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />

    <SnackbarProvider maxSnack={3}>
      <ConfigProvider config={defaultWebConfig}>
        <FirebaseProvider {...{ auth, firestore, storage }}>
          <ApiProvider>
            <SessionProvider>
              <SessionGate
                loadingIndicator={<Loading />}
                onRedirect={redirectToLogin}
              >
                <App />
              </SessionGate>
            </SessionProvider>
          </ApiProvider>
        </FirebaseProvider>
      </ConfigProvider>
    </SnackbarProvider>
  </MuiThemeProvider>
)

export default Root
