import React, { useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useSnackbar } from 'notistack'

import { useMediaQuery, Button } from '@material-ui/core'

import {
  FileCopyOutlined as CopyIcon,
  EmailOutlined as EmailIcon,
  PhoneIphone as PhoneIcon,
} from '@material-ui/icons'

import { useTranslate, defaultLocale } from 'i18n'

import { useConfig } from 'common/config'

import { useApiClient } from 'common/api'

import { useCurrentUser } from 'common/session'

import Logo from 'components/Logo'
import ScrollToTop from 'components/ScrollToTop'
import Page from 'components/Page'
import HeaderContainer from 'components/HeaderContainer'
import Fixed from 'components/Fixed'
import Container from 'components/Container'
import TextInput from 'components/TextInput'
import EmailInput from 'components/EmailInput'
import PhoneNumberInput from 'components/PhoneNumberInput'
import ScrollIndicator from 'components/ScrollIndicator'
import SubmitButton from 'components/SubmitButton'
import Text from 'components/Text'
import useScrolling from 'common/utils/useScrolling'
import Back from 'components/Back'

const Room: React.FC = () => {
  const history = useHistory()
  const { id: roomId } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const t = useTranslate()

  const config = useConfig()

  const client = useApiClient()

  const compact = useMediaQuery<any>((theme) => theme.breakpoints.down('md'))

  const user = useCurrentUser()

  const [{ value: email, valid: emailValid }, setEmail] = useState({
    value: '',
    valid: false,
  })
  const [
    { value: phoneNumber, valid: phoneNumberValid },
    setPhoneNumber,
  ] = useState({
    value: '',
    valid: false,
  })
  const [submitting, setSubmitting] = useState(false)

  const scrolling = useScrolling()

  const videochatUrl = `${config.hostingUrl}/videochat/${roomId}?locale=${
    user.locale || defaultLocale
  }`

  const copyToClipboard = useCallback(() => {
    const input: any = document.getElementById('videochat-url')

    input.select()
    input.setSelectionRange(0, 99999)

    document.execCommand('copy')

    enqueueSnackbar(t('room.clipboard.copied'), { variant: 'success' })
  }, [t, enqueueSnackbar])

  const sendInviteByEmail = useCallback(async () => {
    setSubmitting(true)

    try {
      await client.post(`/videochat/${roomId}/invite/email`, { email })

      setEmail({ value: '', valid: false })

      enqueueSnackbar(t('room.invite.sent'), { variant: 'success' })
    } catch (e) {
      console.error(e)

      enqueueSnackbar(t('room.invite.error'), { variant: 'error' })
    }

    setSubmitting(false)
  }, [t, enqueueSnackbar, client, roomId, email])

  const sendInviteBySms = useCallback(async () => {
    setSubmitting(true)

    try {
      await client.post(`/videochat/${roomId}/invite/sms`, { phoneNumber })

      setPhoneNumber({ value: '', valid: false })

      enqueueSnackbar(t('room.invite.sent'), { variant: 'success' })
    } catch (e) {
      console.error(e)

      enqueueSnackbar(t('room.invite.error'), { variant: 'error' })
    }

    setSubmitting(false)
  }, [t, enqueueSnackbar, client, roomId, phoneNumber])

  const openVideochat = useCallback(
    () => (window.location.href = videochatUrl),
    [videochatUrl]
  )

  const goBack = useCallback(() => history.push('/'), [history])

  const onEmailChange = useCallback(
    (value: string, valid: boolean) => setEmail({ value, valid }),
    []
  )

  const onPhoneNumberChange = useCallback(
    (value: string, valid: boolean) => setPhoneNumber({ value, valid }),
    []
  )

  return (
    <>
      <Page p={compact ? 2 : 3} justifyContent="space-between" maxWidth={600}>
        <HeaderContainer mb={compact ? 3 : 6}>
          <Logo large={!compact} />
        </HeaderContainer>

        <Container mb={compact ? 3 : 6}>
          <Text mb={compact ? 2 : 4}>{t('room.description')}</Text>

          <Container mb={compact ? 2 : 4}>
            <Container mb={compact ? 1 : 2}>
              <TextInput id="videochat-url" value={videochatUrl} />
            </Container>

            <Button
              size="large"
              color="primary"
              fullWidth
              onClick={copyToClipboard}
            >
              <Container fullWidth={false} mr>
                <CopyIcon />
              </Container>
              {t('room.clipboard.copy')}
            </Button>
          </Container>

          <Container mb={compact ? 2 : 4}>
            <Container mb={compact ? 1 : 2}>
              <EmailInput
                label={t('email')}
                placeholder={t('email.placeholder')}
                value={email}
                disabled={submitting}
                onEmailChange={onEmailChange}
                onSubmit={sendInviteByEmail}
              />
            </Container>

            <Button
              size="large"
              color="primary"
              fullWidth
              disabled={!emailValid || submitting}
              onClick={sendInviteByEmail}
            >
              <Container fullWidth={false} mr>
                <EmailIcon />
              </Container>
              {t('room.invite.email')}
            </Button>
          </Container>

          <Container mb={compact ? 2 : 4}>
            <Container mb={compact ? 1 : 2}>
              <PhoneNumberInput
                label={t('phoneNumber')}
                placeholder={t('phoneNumber.placeholder')}
                value={phoneNumber}
                disabled={submitting}
                onPhoneNumberChange={onPhoneNumberChange}
                onSubmit={sendInviteBySms}
              />
            </Container>

            <Button
              size="large"
              color="primary"
              fullWidth
              disabled={!phoneNumberValid || submitting}
              onClick={sendInviteBySms}
            >
              <Container fullWidth={false} mr>
                <PhoneIcon />
              </Container>
              {t('room.invite.sms')}
            </Button>
          </Container>
        </Container>

        <Container>
          <SubmitButton
            label={t('room.videochat.open')}
            fullWidth
            onClick={openVideochat}
          />
        </Container>
      </Page>

      <Fixed horizontal="left" vertical="top" spacing>
        <Back shade={compact && scrolling} onClick={goBack} />
      </Fixed>

      <Fixed horizontal="right" vertical="bottom" spacing>
        <ScrollIndicator shade={compact} />
      </Fixed>

      <ScrollToTop />
    </>
  )
}

export default Room
