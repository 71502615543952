export default {
  'app.name': 'Min Doktor',
  'app.tagline': 'Träffa dina patienter snabbt och enkelt.',

  yes: 'Ja',
  no: 'Nej',
  unknown: 'Vet ej',
  confirm: 'Bekräfta',
  next: 'Nästa',
  skip: 'Hoppa över',
  login: 'Logga in',
  logout: 'Logga ut',
  anonymous: 'Anonym',
  notAllowed: 'Inte tillåten',
  name: 'Namn',
  title: 'Titel',

  email: 'Email',
  'email.placeholder': 'dinemail@email.com',
  'email.invalid': 'Ogiltig email',

  phoneNumber: 'Telefonnummer',
  'phoneNumber.placeholder': '+46765260000',
  'phoneNumber.invalid': 'Ogiltigt telefonnummer',

  ssn: 'Personnummer',
  'ssn.placeholder': 'YYYYMMDD-XXXX',
  'ssn.invalid': 'Ogiltigt personnnummer',

  'question.placeholder': 'Skriv ditt svar här',

  profile: 'Min profil',
  'profile.save': 'Spara profil',
  'profile.save.error': 'Misslyckades med att spara profil',

  'locale.label': 'Språk',
  'locale.english': 'Engelska',
  'locale.swedish': 'Svenska',

  'room.create': 'Skapa videorum',
  'room.create.error': 'Misslyckades med att skapa videorum',
  'room.description':
    'Du kan skicka en inbjudan till vem du vill. Länken är giltig i 24 timmar. Efter det går det såklart bra att skapa ett nytt videorum.',
  'room.clipboard.copy': 'Kopiera till clipboard',
  'room.clipboard.copied': 'Kopierat till clipboard',
  'room.invite.sent': 'Skickat inbjudan',
  'room.invite.error': 'Misslyckades med att skicka inbjudan',
  'room.invite.email': 'Skicka inbjudan med email',
  'room.invite.sms': 'Skicka inbjudan med SMS',
  'room.videochat.open': 'Öppna videomötet',

  'login.bankid': 'Logga in med BankID',
  'login.bankid.error': 'Misslyckades med att logga in med BankID',
  'login.bankid.description':
    'Vi använder BankID för att ge en trygg och säker identifiering.',
  'login.bankid.this.login': 'BankID på denna enheten',
  'login.bankid.this.polling':
    'BankID appen kommer att startas automatiskt om den är installerad på den här enheten.',
  'login.bankid.other.login': 'BankID på annan enhet',
  'login.bankid.other.polling':
    'Starta BankID appen på den mobila enheten där den är installerad.',

  'login.email': 'Logga in med email',
  'login.email.error': 'Misslyckades med att logga in med email',
  'login.email.link.description':
    'Vi kommer att skicka en länk till din email. Klicka på den för att logga in.',
  'login.email.link.error': 'Misslyckades med att skicka länk',
  'login.email.link.send': 'Skicka länk',
  'login.email.sent.description':
    'Vi har skickat en länk till din email. Klicka på den för att logga in.',
  'login.email.confirm': 'Bekräfta email',
  'login.email.confirm.description':
    'Du behöver bekräfta din email för att logga in.',

  'login.google': 'Logga in med Google',

  'invite.email.subject': 'Inbjudan till videomöte från Min Doktor',
  'invite.email.body':
    'Klicka <a href="{link}">här</a> för att öppna videomötet',
  'invite.sms.text': 'Inbjudan till videomöte från Min Doktor',

  integrity: 'Integritet',
  'integrity.policy.label': 'Din integritet är viktig för oss.',
  'integrity.policy.description':
    'Vi har tagit fram en integritetspolicy och uppdaterat våra användarvillkor i enlighet med [dataskyddsförordningen (GDPR)](https://sv.wikipedia.org/wiki/Allm%C3%A4nna_dataskyddsf%C3%B6rordningen). Vår nya policy ger dig möjlighet att anpassa Min Doktor efter dina behov och att ha kontroll över de uppgifter du delger oss.\n\nLäs gärna vår [integritetspolicy](modal:integrityPolicy) och våra [användarvillkor](modal:tos). Du godkänner dem genom att trycka OK.',
  'integrity.policy.accept': 'OK',
  'integrity.policy.settings': 'Personliga inställningar',
  'integrity.settings.label': 'Personliga inställningar',
  'integrity.settings.description':
    'Du har möjlighet att göra vissa personliga inställningar nedan. Du kan alltid ändra dessa inställningar senare.\n\n_Observera att dessa inställningar endast gäller icke-medicinska syften, och din möjlighet att söka vård hos oss påverkas inte av vilka inställningar du väljer att göra. Din patientjournal skyddas alltid av sekretess och som vårdgivare är vi skyldiga att följa patientsäkerhetslagen (PSL)._',
  'integrity.settings.marketing.label': 'Marknadskommunikation',
  'integrity.settings.marketing.description':
    'Tillåter oss att berätta för dig om vi t.ex. utökar vårt utbud samt att skicka nyhetsbrev och tips som vi tror kan vara relevanta för dig.',
  'integrity.settings.tracking.label': 'Personanpassning',
  'integrity.settings.tracking.description':
    'Tillåter oss att analysera ditt användarbeteende för att skapa en anpassad upplevelse. Vi tittar t.ex. på hur du hittade till oss och vilken webbläsare du använder.',

  'register.name': 'Vad heter du?',
  'register.error': 'Misslyckades med att spara profil',

  'videochat.join': 'Hoppa in',
}
