import firebaseConfig from './firebase'
import localConfig from './local'

import { Config } from './types'

export default (options?: {
  localHosting?: boolean
  localApi?: boolean
  localStore?: boolean
  persistentCache?: boolean
}): Config => {
  return {
    firebase: firebaseConfig,
    local: localConfig,
    functionsUrl: options?.localApi
      ? localConfig.functionsUrl
      : firebaseConfig.functionsUrl,
    hostingUrl: options?.localHosting
      ? localConfig.hostingUrl
      : firebaseConfig.hostingUrl,
    emulatedStore: !!options?.localStore,
    persistentCache: !!options?.persistentCache,
  }
}
