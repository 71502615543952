import en from './translations/en'
import sv from './translations/sv'

import { Locale } from './types'

export type Key = keyof typeof sv

export default (locale: Locale, key: Key): string => {
  if (locale === 'sv-SE') {
    return sv[key]
  }

  if (locale === 'en-GB') {
    if ((en as any)[key]) {
      return (en as any)[key]
    }
  }

  return `[${key}]`
}
