export const spacingUnit = 8

export interface SpacingProps {
  m?: boolean | number
  mt?: boolean | number
  mb?: boolean | number
  ml?: boolean | number
  mr?: boolean | number
  mh?: boolean | number
  mv?: boolean | number
  p?: boolean | number
  pt?: boolean | number
  pb?: boolean | number
  pr?: boolean | number
  pl?: boolean | number
  ph?: boolean | number
  pv?: boolean | number
}

export const getSpacingStyle = ({
  m,
  mt,
  mb,
  ml,
  mr,
  mh,
  mv,
  p,
  pt,
  pb,
  pr,
  pl,
  ph,
  pv,
}: SpacingProps): {
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  paddingTop?: number
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
} => {
  const s = (nbr: boolean | number) =>
    spacingUnit * (nbr === true ? 1 : nbr === false ? 0 : nbr)

  return {
    ...(mt !== undefined ? { marginTop: s(mt) } : {}),
    ...(mb !== undefined ? { marginBottom: s(mb) } : {}),
    ...(ml !== undefined ? { marginLeft: s(ml) } : {}),
    ...(mr !== undefined ? { marginRight: s(mr) } : {}),
    ...(mh !== undefined ? { marginLeft: s(mh), marginRight: s(mh) } : {}),
    ...(mv !== undefined ? { marginTop: s(mv), marginBottom: s(mv) } : {}),
    ...(m !== undefined ? { margin: s(m) } : {}),
    ...(pt !== undefined ? { paddingTop: s(pt) } : {}),
    ...(pb !== undefined ? { paddingBottom: s(pb) } : {}),
    ...(pl !== undefined ? { paddingLeft: s(pl) } : {}),
    ...(pr !== undefined ? { paddingRight: s(pr) } : {}),
    ...(ph !== undefined ? { paddingLeft: s(ph), paddingRight: s(ph) } : {}),
    ...(pv !== undefined ? { paddingTop: s(pv), paddingBottom: s(pv) } : {}),
    ...(p !== undefined ? { padding: s(p) } : {}),
  }
}
