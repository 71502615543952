import { createContext } from 'react'

import { User } from '../users'

import { Actions } from './types'

const context = createContext<
  | {
      actions: Actions
      loaded?: boolean
      user?: User
    }
  | undefined
>(undefined)

export default context
