import React, { useEffect } from 'react'

const scrollToTop = () => window.scrollTo(0, 0)

const ScrollToTop: React.FC = () => {
  useEffect(() => scrollToTop(), [])

  return null
}

export default ScrollToTop
