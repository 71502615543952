import React from 'react'

import context from './context'
import { Config } from './types'

const Provider: React.FC<{ config: Config }> = ({ config, children }) => {
  return <context.Provider value={config}>{children}</context.Provider>
}

export default Provider
