import { useState, useEffect } from 'react'

const getScrollTop = () =>
  (document.scrollingElement || document.documentElement).scrollTop

const useScrolling = (): boolean => {
  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const fn = () => setScrolling(getScrollTop() > 0)
    document.addEventListener('scroll', fn)
    return () => document.removeEventListener('scroll', fn)
  }, [])

  return scrolling
}

export default useScrolling
