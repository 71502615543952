import { ApiClient } from '../api'

import { Auth } from '../firebase'

import { Actions } from './types'

export const saveProfile = (
  client: ApiClient
): Actions['saveProfile'] => async (data, options) => {
  await client.post('/profile', { data, options })
}

export const logout = (auth: Auth): Actions['logout'] => () => auth().signOut()
