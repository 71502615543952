import React from 'react'
import classNames from 'classnames'

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Typography,
  Link,
} from '@material-ui/core'

import ReactMarkdown from 'react-markdown'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
    },

    bold: {
      fontWeight: 600,
    },

    paragraph: {
      margin: 0,
      marginBottom: spacing(),
      '&:last-child': {
        marginBottom: 0,
      },
    },

    list: {
      paddingLeft: 18,
    },

    heading: {
      fontWeight: 600,
      marginBottom: spacing(),
      '&:last-child': {
        marginBottom: 0,
      },
    },

    typography: {
      wordBreak: 'break-word',
    },

    link: {
      fontWeight: 600,
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    image: {
      maxWidth: '100%',
      borderRadius: 3,
    },

    video: {
      width: '100%',
      borderRadius: 3,
    },
  })

type TypographyVariant = 'body1' | 'body2'

const renderers = ({
  typographyVariant = 'body1',
  classes,
  className,
  style,
}: {
  typographyVariant?: TypographyVariant
  className?: string
  style?: any
} & WithStyles<typeof styles>) => ({
  root: function Root({ children }: { children?: React.ReactNode }) {
    return (
      <div className={classNames(classes.root, className)} style={style}>
        {children}
      </div>
    )
  },

  paragraph: function Paragraph({ children }: { children?: React.ReactNode }) {
    return (
      <Typography
        variant={typographyVariant}
        className={classNames(classes.typography, classes.paragraph)}
      >
        {children}
      </Typography>
    )
  },

  list: function List({ children }: { children?: React.ReactNode }) {
    return (
      <ul className={classNames(classes.paragraph, classes.list)}>
        {children}
      </ul>
    )
  },

  listItem: function ListItem({ children }: { children?: React.ReactNode }) {
    return (
      <li>
        <Typography variant={typographyVariant} className={classes.typography}>
          {children}
        </Typography>
      </li>
    )
  },

  heading: function Heading({ children }: { children?: React.ReactNode }) {
    return (
      <Typography variant={typographyVariant} className={classes.heading}>
        {children}
      </Typography>
    )
  },

  link: function MdLink({
    href,
    children,
  }: {
    href?: string
    children?: React.ReactNode
  }) {
    return (
      <Link
        href={href}
        variant={typographyVariant}
        className={classNames(classes.typography, classes.link)}
      >
        {children}
      </Link>
    )
  },

  strong: function Strong({ children }: { children?: React.ReactNode }) {
    return <span className={classes.bold}>{children}</span>
  },

  image: function Image({ alt, src }: { alt?: string; src?: string }) {
    if (alt === 'video') {
      const [videoSrc, posterSrc, ...subtitles] = (src || '').split(',')

      return (
        <video controls poster={posterSrc} className={classes.video}>
          <source src={videoSrc} />
          {subtitles.map((subtitle, i) => {
            const [lang, subtitleSrc] = subtitle.split(':', 2)

            return (
              <track
                key={lang}
                kind="subtitles"
                srcLang={lang}
                src={subtitleSrc}
                default={i === 0}
              />
            )
          })}
        </video>
      )
    }

    return <img alt={alt} src={src} className={classes.image} />
  },
})

const Markdown = withStyles(styles)(
  ({
    typographyVariant,
    children,
    classes,
    className,
    style,
  }: {
    typographyVariant?: TypographyVariant
    children?: React.ReactNode
    className?: string
    style?: any
  } & WithStyles<typeof styles>) => (
    <ReactMarkdown
      renderers={renderers({ typographyVariant, classes, className, style })}
    >
      {children}
    </ReactMarkdown>
  )
)

export default Markdown
